<template>
  <div class="main">
    <!-- 顶部标题 -->
    <div class="header">
      <div class="header-title">
        <b class="title">打印记录查询</b
        >
        <!-- <span style="color: #555555; font-size: 12px; margin-left: 8px"
          >(最近30天)</span
        > -->
      </div>
    </div>
    <!-- 头部查询 -->
    <div class="select">
      <el-form
        inline
        :model="queryData"
        ref="ruleForm"
        :rules="rules"
        label-min-width="70px"
      >
        <el-form-item style="display: block" label="查询类型：">
          <el-radio-group v-model="radioType" @change="radioChange">
            <el-radio
              class="color-green"
              style="color: #00802f; font-weight: bold"
              label="快递单"
            ></el-radio>
            <el-radio
              class="color-blue"
              style="color: #38a4e2; font-weight: bold"
              label="发货单"
            ></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          style="display: block"
          label="打印时间："
          prop="startTime"
        >
          <el-date-picker
            v-model="queryData.startTime"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            size="mini"
            style="width: 310px"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 快递单 -->
        <div style="position: relative">
          <div class="select-msg">查询条件：</div>
          <div style="display: inline-block; padding-left: 82px">
            <el-form-item class="header-txt">
              <el-input v-model="queryData.shopName" :disabled="true"></el-input>
            </el-form-item>
            <!-- <el-form-item>
              <el-select
                v-model="queryData.expressList"
                placeholder="所有快递"
                size="mini"
                style="width: 110px"
              >
                <el-option
                  v-for="item in selectType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.company_no"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="分销商名称" class="header-txt">
              <el-input v-model="queryData.name"></el-input>
            </el-form-item>
            <el-form-item label="订单号" class="header-txt">
              <el-input v-model="queryData.bianhao"></el-input>
            </el-form-item>
            <el-form-item >
              <label class="el-form-item__label" style="color:#49689a;">打印次数</label>
              <el-select
                v-model="queryData.dayin"
                size="mini"
                style="width: 110px; "
                placeholder="等于"
              >
                <el-option
                  v-for="(item, index) in stampNum"
                  :key="index"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="print-txt">
              <el-input v-model="queryData.dayinNum" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
            </el-form-item>
            <!-- <el-form-item label="BIC编码" class="header-txt">
              <el-input v-model="queryData.BICNum"></el-input>
            </el-form-item> -->
          </div>
        </div>
      </el-form>
      <div class="select-btn">
        <el-button type="primary" size="mini" @click="handleSelect"
          >查询</el-button
        >
        <!-- <el-button type="primary" size="mini" @click="handleDownLoads"
          >下载</el-button
        > -->
        <!-- <el-button type="primary" size="mini"  @click="handlePrintClear"
          >打印序号清零</el-button
        >
        <a
          @click="handlePrintSetShow"
          href="javaScript:;"
          style="font-size: 14px; text-decoration: underline; margin-left: 10px"
          >打印序号设置</a
        > -->
      </div>
    </div>
    <!-- 表格数据 -->
    <div class="table-data"  v-if="tableShow">
      <div class="table-top">
        共<span class="color-red">{{ distributor_num_show }}</span
        >买家/<span class="color-red">{{ order_num_show }}</span
        >订单/<span class="color-red">{{ totalNum }}</span
        >条记录
        <!-- <span style="color: #c5baba; padding: 0px 2px">(说明)</span> -->
        <!-- <span style="color: rgb(114 114 114); padding: 0px 2px"
          >(默认只能下载1万单)</span
        > -->
        <!-- 分页 -->
        <div style="float: right">
          每页显示
          <el-pagination
            background
            layout="sizes,prev, pager, next"
            :total="totalNum"
            style="display: inline-block"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :current-page="currentPage"
            :page-size="pageNum"
            :page-sizes="[50, 100, 200]"
            v-if='pageShow'
          >
          </el-pagination>
        </div>
      </div>

      <!-- 表格数据 -->
      <el-table
        ref="multipleTable"
        :data="expressData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column width="100" prop="id"></el-table-column>
        <el-table-column prop="nickname" label="分销商名称"></el-table-column>
        <el-table-column prop="transaction_id" label="订单号" >
        </el-table-column>
        <el-table-column prop="batch" label="发货单"></el-table-column>
        <el-table-column width="100" prop="number" label="打印序号">
        </el-table-column>
        <el-table-column prop="create_time" label="打印时间">
        </el-table-column>
      </el-table>
    </div>
    <!-- 打印序号设置 -->
    <div class="printNum">
      <el-dialog title="打印序号设置" :visible.sync="printSetShow">
        <el-form ref="form" :model="printSetData" label-width="150px">
          <el-form-item label="清零类型：">
            <el-select v-model="printSetData.clearType" placeholder="请选择">
              <el-option
                v-for="item in clearTypeList"
                :key="item.title"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="清零设置：">
            <el-radio-group v-model="printSetData.clearSet">
              <el-radio
                v-for="item in clearSetList"
                :key="item.id"
                :label="item.title"
                style="display: block; padding: 15px 5px"
                >{{ item.title
                }}<span style="color: rgb(149 141 141); padding-left: 2px">{{
                  item.value
                }}</span></el-radio
              ></el-radio-group
            >
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-1-footer">
          <el-button type="primary" size="mini" @click="printSetSave"
            >确定</el-button
          >
          <el-button size="mini" style="color:black;background-color:transparent ;" @click="printSetShow = false" >取消</el-button>
        </span>
      </el-dialog>
    </div>

    <div class="print-show-clear">
      <el-dialog :visible.sync="printClearShow">
        <div style="display:flex;">
          <div ><img style="margin:15px 10px; width:50px;" :src="OSS+'/icon.png'" alt=""></div>
          <div>
             <h1 style="color:#ff7614;">请注意</h1>
          <p style="font-size:18px;width:386px;">打印序号清零后将从0开始计数，且不能恢复。是否确认继续操作？</p>
          </div>
        </div>
        
        <span slot="footer" class="dialog-1-footer">
          <el-button type="primary" size="mini" @click="printClearDetermine"
            >确定</el-button
          >
          <el-button size="mini" style="color:black;background-color:transparent ;" @click="printClearShow = false">取消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      expressData: (state) => state.utilities.print_list,
      selectType:(state) => state.print.expressList,
      shopInfo: (state) => state.setting.get_shop_info.data,
    }),
  },
  data() {
    return {
      pickerOptions: {
        disabledDate:(date)=>{ // 限制查询3个月内数据
            let moment = this.moment
            if(
                moment(date).isBefore(moment().subtract(2, 'M').startOf("M"))
                || moment(date).isAfter(moment().endOf("D"))
            ){
                return true
            }
        }
      },
      rules: {
        startTime: [{ required: true, message: "请选择日期", trigger: "blur" }],
      },
      beginTime:'',
      endTime:'',
      params: {},
      tableShow:false,
      printSetShow: false,
      printShow: false,
      printClearShow:false,
      radio: "",
      radioType: "发货单",
      expressShow: true, //快递单
      queryData: {
        startTime: [], //开始时间
        shopName: "",
        expressList: "所有快递", //快递
        bianhao: "",
        name: "",
        dayin: "等于",
        dayinNum: "",
        BICNum: "",
      },
      // 查询条件
      // selectType: [
      //   { id: "0", value: "所有快递" },
      //   { id: "1", value: "圆通" },
      //   { id: "2", value: "中通" },
      //   { id: "3", value: "顺丰" },
      //   { id: "4", value: "EMS经济" },
      // ],

      //打印次数
      stampNum: [
        { id: "0", value: "等于" },
        { id: "1", value: "小于" },
        { id: "2", value: "大于" },
      ],
      // 清零类型
      clearTypeList: [
        { id: "1", title: "同时清零" },
        { id: "2", title: "仅清零快递单" },
        { id: "3", title: "仅清零发货单" },
        ],
      // 清零设置
      clearSetList: [
        { id: "1", title: "不清零", value: "打印序号不自动清零" },
        { id: "2", title: "日清零", value: "每日零点自动清零" },
        { id: "3", title: "月清零", value: "每月1日零点自动清零" },
      ],
      // 打印序号设置
      printSetData: { clearSet: "不清零", clearType: "同时清零" },
      // 分页
      currentPage: 1,
      offset: 1, //页码
      pageNum: 50, //每页显示几条
      totalNum: 0,//订单记录数
      order_num_show:0,//订单数
      distributor_num_show:0,//买家数
      tableId:0,
      selectTypeList:[],
      pageShow:true
    };
  },
  methods: {
    ...mapActions("utilities", ["getPrintRecordList"]),
    handleSelect() {
      this.currentPage = 1
      this.offset = 1
      this.pageShow = false
     setTimeout(()=> {
        this.pageShow = true
      },0)
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return false;
        let operation = "";
        if (this.queryData.dayin == "等于") {
          operation = "=";
        } else if (this.queryData.dayin == "小于") {
          operation = "<";
        } else {
          operation = ">";
        }
        this.params = {
          express_code:this.queryData.expressList=='所有快递'?'':this.queryData.expressList,
          start_time: this.queryData.startTime[0],
          end_time: this.queryData.startTime[1],
          transaction_id: this.queryData.bianhao,
          express_number: this.queryData.oderNum,
          nickname: this.queryData.name,
          operation:operation,
          count:this.queryData.dayinNum
        };
        this.getList();
      });
    },
    radioChange(val) {
      this.$emit("getDelGoods", val);
    },
    // handleDownLoads() {
    //   console.log("点击了下载");
    // },
    printSetSave() {
      console.log("点击了保存");
    },
    printClearDetermine(){
      console.log("点击了确定");
    },
    handlePrintSetShow(){
      this.printSetData={ clearSet: "不清零", clearType: "同时清零" },
      this.printSetShow = true
    },
    handlePrintClear(){  
      this.printClearShow=true
    },
    getList() {
      this.tableShow=true
      let params = {
        limit: this.pageNum,
        pageNum: this.offset,
        type: this.radioType == "发货单" ? 1 : 0,
        start_time: this.getBeginTime(),
        end_time: this.getEndTime(),
      };
      params = { ...params, ...this.params };
      this.getPrintRecordList(params)
        .then((res) => {
          // console.log(res)
          let data=res.data
          // console.log(data.data);
          // for(let i in data.data){
          //   data.data[i].id=parseInt(i)+1
          //   //  console.log(data.data[i]);
          //   // this.tableId=i
          // } 
          // this.totalNum = data.total;
          this.totalNum=res.data==false?'0':res.data.total  // 记录数
          this.order_num_show=res.data==false?'0':res.data.order_num  // 订单数
          this.distributor_num_show=res.data==false?'0':res.data.distributor_num  // 买家数
        })
        .catch((error) => {});
      //  this.$store.dispatch('utilities/getPrintRecordList',params);
    },
    handleCurrentChange(page) {
      this.offset = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.offset=1
      this.pageNum = val;
      this.getList();
      this.pageShow = false
      setTimeout(()=> {
        this.pageShow = true
      },0)
    },
    getBeginTime() {
      let now = new Date().getTime();
      let days = 13;
      let agoTimes = now - 86400 * 1000 * days;
      let time = new Date(agoTimes);
      let year = time.getFullYear();
      let mounth = time.getMonth() + 1;
      let date = time.getDate() ;
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 00:00:00";
    },
    getEndTime() {
      var time = new Date();
      var year = time.getFullYear();
      var mounth = time.getMonth() + 1;
      var date = time.getDate();
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 23:59:59";
    },
  },
  created() {
    this.queryData.startTime.push(this.getBeginTime());
    this.queryData.startTime.push(this.getEndTime());
  },
  mounted() {
  this.queryData.shopName = this.shopInfo.shop_name
  this.$store.dispatch('print/get_express_list').then(()=> {
      this.selectTypeList=this.selectType
      this.selectTypeList.unshift({id:'10',name:'全部快递',company_no:''})
      this.$store.commit("print/SET_EXPRESS_LIST", this.selectTypeList);
    })
  }
};
</script>

<style  scoped>
.main {
  padding: 0 10px;
}
.color-red {
  color: red;
  padding: 0px 2px;
}
.header-title {
  height: 55px;
  line-height: 55px;
  font-size: 20px;
  color: rgb(56, 162, 219);
  border-bottom: 1px dotted rgb(85, 85, 85);
}
.select,.table-data {
    padding-left: 24px
}
.select-msg {
  display: inline-block;
  color: black;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: 10px;
  z-index: 999;
}
* >>> .el-form-item__label {
  font-weight: 600;
  color: black;
}
.header-txt >>> .el-form-item__label {
  font-weight: 600;
  color: #49689a;
}
.el-form-item {
  margin-bottom: 0px;
  height: 40px;
}
.header-txt >>> .el-input__inner {
  width: 150px;
  height: 22px;
}
.print-txt >>> .el-input__inner {
  width: 140px;
  height: 22px;
}
.pici >>> .el-input__inner {
  width: 200px;
}
.btn-white {
  color: black;
  border: 1px solid #b3abab;
  background: linear-gradient(#fffcf7, #edeaea);
}
.select-btn {
  padding: 20px 70px 20px 85px;
  display: flex;
  /* width: 350px; */
  width: 120px;
  justify-content: space-between;
}
.icon {
  position: absolute;
  top: 14px;
  left: -15px;
}
.table-top {
  height: 40px;
  line-height: 30px;
  border-bottom: 3px solid rgb(56, 162, 219);
}
.color-green >>> .el-radio__input.is-checked + .el-radio__label {
  color: rgb(0, 128, 47);
}

.color-blue >>> .el-radio__input.is-checked + .el-radio__label {
  color: #409eff;
}
/* 清零弹框 */
.print-show-clear >>> .el-dialog {
  background-color: #fff;
  width: 520px;
}
.print-show-clear >>> .el-dialog__body {
  padding: 10px 20px 70px 30px !important;
}
.print-show-clear >>> .el-dialog__footer{
background-color: #f3f3f3;
    border-top: 1px solid #eaeaea;
    }
/* 打印序号设置提示框 */
.printNum >>> .el-form-item {
  height: auto !important;
}
.printNum >>> .el-dialog {
  border: 10px solid #9e9e9e;
  box-sizing: content-box;
  overflow: hidden;
  background: #fff;
}
.printNum >>> .el-dialog__title {
  display: block;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 1000;
  color: #49689a;
}
.printNum >>> .el-dialog__header {
  box-sizing: border-box;
  padding: 0;
  width: auto;
  border-top: 10px solid #f6881e;
  border-bottom: 2px solid #e6e6e6;
}
.printNum >>> .el-input--suffix .el-input__inner {
  width: 120px;
  height: 24px;
}
.printNum >>> .el-dialog__headerbtn {
  position: absolute;
  top: 18px;
  right: 5px;
  height: auto;
  width: auto;
  background: none;
  border-radius: 0;
  font-size: 17px;
}
.printNum >>> .el-dialog__headerbtn .el-dialog__close {
  color: #000;
}
.printNum >>> .el-dialog__body {
  padding: 10px;
}
.printNum >>> .el-dialog__footer {
  padding: 10px;
  background-color: #f3f3f3;
}
*
  >>> .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: "";
  margin-right: 0px;
}
* >>> .el-table td,
.el-table th.is-leaf {
  border-bottom: 2px dotted #e2e2e2;
}
* >>> .el-table .cell {
  text-align: center;
}
</style>